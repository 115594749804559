<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  الأرقام الخاصة بالفرد">
          <div>
            <b-row v-for="(phone, i) in person.contact_details" :key="i">
              <b-col md="2" xl="3">
                <b-form-group label-for="call">
                  <label> وسبلة الاتصال</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.contact_type.name }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="3" xl="2">
                <b-form-group label-for="cardNumber">
                  <label> الرقم</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.number }}
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="2">
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <h5 class="text-capitalize mb-75">
                    {{ phone.notes }}
                  </h5>
                </b-form-group>
              </b-col>

              <br />
              <br />
            </b-row>
            <b-row>

               <b-table
                ref="refServiceListTable"
                class="position-relative"
                :items="person.other_contact_details"
                responsive
                :fields="tableColumns"

                show-empty
                empty-text="No matching records found"

              ></b-table>
            </b-row>
          </div>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
  BTable
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BForm,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
    BTable
  },

  props: {
    person: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
            tableColumns : [
    { key: "number", label: " الرقم"},
    { key: "relation", label: "صلة القرابى"},
    { key: "notes", label: "الملاحظات"},




  ],
  test:[{id:
4276,
notes
:
"نوتس وسيلة اتصال",
number
:
"123123",
relation
:
"أب"}]
        }
    }
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
